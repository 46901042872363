<template>
  <div class="client-notes-component">
    <div
      v-if="editPermission && !isLoading && config && config[mode] && config[mode].fields_to_edit"
      class="client-notes-component-edit-icon"
    >
      <v-icon medium color="warning" @click="openUpdateDialog()">mdi-pencil-box-outline</v-icon>
    </div>
    <div v-if="isLoading" style="display: flex; justify-content: center; padding: 20px 0">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-if="!isLoading && values">
      <div
        v-for="(item, index) in values"
        v-show="!item.showHasValue || (item.showHasValue && item.value && item.value !== '-')"
        :key="index"
        class="row-flex-wrap client-notes-component-row"
      >
        <span class="client-notes-component-title" :class="[item.type + '--text']">{{ item.title }}:</span>
        <template v-for="(subItem, subItemIndex) in displayedItems(item)">
          <span
            v-if="!item.value && item.items"
            :key="subItemIndex"
            class="client-notes-component-tag"
            :class="{
              'client-notes-component-tag-active':
                (item.id === 'accepted_foods' && planPoods && planPoods.indexOf(subItem.id) > -1) ||
                (item.id === 'include_recipes' && subItem.name && planRecipes && planRecipes.indexOf(subItem.name.trim()) > -1),
              'cursor-hover':
                openValuesCallback &&
                openValuesCallback[item.id] &&
                (item.id !== 'include_recipes' ||
                  (item.id === 'include_recipes' && subItem.name && planRecipes && planRecipes.indexOf(subItem.name.trim()) === -1)),
            }"
            @click="
              openValuesCallback &&
              openValuesCallback[item.id] &&
              (item.id !== 'include_recipes' ||
                (item.id === 'include_recipes' && subItem.name && planRecipes && planRecipes.indexOf(subItem.name.trim()) === -1))
                ? openValuesCallback[item.id](subItem)
                : null
            "
          >
            {{ subItem.name }}
            <v-icon
              v-if="subItem.description"
              color="purple"
              dark
              class="cursor-hover ml-1"
              @click.stop="showInformation(subItem.description)"
            >
              mdi-information
            </v-icon>
          </span>
        </template>
        <span
          v-if="showMoreItemsButton && showAllItemsState[item.id] === false"
          class="client-notes-component-tag client-notes-component-tag-more cursor-hover"
          @click="showAllItemsState[item.id] = true"
        >
          {{ str['show_all'].toUpperCase() }}
        </span>
        <span v-if="!item.value && item.items && !item.items.length" class="client-notes-component-value">-</span>
        <span v-if="!item.icon && item.value" class="client-notes-component-value">
          {{ item.value }}
        </span>
        <span v-if="item.icon && item.value" class="client-notes-component-value">
          <v-icon color="info" dark class="cursor-hover" @click.stop="showInformation(item.value)">mdi-information</v-icon>
        </span>
      </div>
    </div>

    <v-dialog v-model="dialogUpdate" persistent max-width="750px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['notes'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col v-for="item in form[mode]" :key="item.id" cols="12" :class="{ 'form-fields-colors': showTextColors }">
                <v-text-field
                  v-if="item.type === 'input'"
                  v-model="item.value"
                  :label="item.title"
                  :disabled="item.disabled ? true : false"
                  :class="[item.color ? item.color + '-label' : null]"
                />
                <v-autocomplete
                  v-if="item.type === 'select'"
                  v-model="item.value"
                  :label="item.title"
                  :item-text="item.selectKeys && item.selectKeys.name ? item.selectKeys.name : 'name'"
                  :item-value="item.selectKeys && item.selectKeys.id ? item.selectKeys.id : 'id'"
                  :items="item.items"
                  :no-data-text="str['no_data']"
                  :multiple="item.multiple"
                  :return-object="item.returnObject"
                  :search-input.sync="item.searchInput"
                  :class="[item.color ? item.color + '-label' : null]"
                  @focus="refreshSelectItems(item)"
                  @blur="item.searchInput = ''"
                  @change="changeSelect(item)"
                />
                <v-textarea
                  v-if="item.type === 'textarea'"
                  v-model="item.value"
                  :label="item.title"
                  rows="3"
                  :class="[item.color ? item.color + '-label' : null]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="closeUpdateDialog">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn color="success" @click="saveUpdateDialog">
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'

export default {
  name: 'ClientNotes',
  props: {
    client: Object,
    mode: String,
    foods: Object,
    planPoods: Array,
    planRecipes: Array,
    recipes: Object,
    exercises: Object,
    openValuesCallback: Object,
  },
  data() {
    const user = Utils.getUser()
    const config = user && user.configurations && user.configurations.notes ? user.configurations.notes : null
    return {
      user: user,
      str: window.strings,
      config: config,
      showTextColors: config && config.show_text_colors ? true : false,
      showMoreItemsButton: config && config.show_more_items_button ? true : false,
      showMoreItemsCount: 10,
      isLoading: false,
      itemsDict: {},
      values: '',
      form: {
        nutrition: [],
        training: [],
      },
      dialogUpdate: false,
      editPermission: Utils.hasPermission('user_notes_edit'),
      showAllItemsState: {},
    }
  },
  beforeMount: function () {
    if (!this.user || !this.config) {
      return false
    }
    this.main()
  },
  methods: {
    displayedItems: function (item) {
      if (this.showMoreItemsButton && this.showAllItemsState[item.id] === false) {
        return item.items.slice(0, this.showMoreItemsCount)
      }
      return item.items
    },
    main: function () {
      this.isLoading = true
      switch (this.mode) {
        case 'nutrition':
          this.handleNutrition()
          break
        case 'training':
          this.handleTraining()
          break
      }
    },
    handleNutrition: function () {
      const self = this
      this.setFieldsToEdit()
      if (this.foods) {
        this.itemsDict.foods = this.foods
        this.getRecipes(function () {
          self.refreshUpdateForm()
          self.setValues()
          self.loaded()
        })
      } else {
        Api.getFoods(
          {
            useCache: true,
          },
          function (response) {
            if (response.success) {
              const foods = {}
              response.data.forEach(function (item) {
                foods[item.id] = item
              })
              self.itemsDict.foods = foods
              self.getRecipes(function () {
                self.refreshUpdateForm()
                self.setValues()
                self.loaded()
              })
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      }
    },
    getRecipes: function (callback) {
      const self = this
      if (this.recipes) {
        this.itemsDict.recipes = this.recipes
        callback()
      } else {
        Api.getRecipes(
          {
            useCache: true,
          },
          function (response) {
            if (response.success) {
              const recipes = {}
              response.data.forEach(function (item) {
                recipes[item.id] = {
                  id: item.id,
                  name: item.name.trim(),
                }
              })
              self.itemsDict.recipes = recipes
              callback()
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      }
    },
    handleTraining: function () {
      const self = this
      this.setFieldsToEdit()
      if (this.exercises) {
        this.itemsDict.exercises = this.exercises
        this.refreshUpdateForm()
        this.setValues()
        this.loaded()
      } else {
        Api.getWorkouts(
          {
            useCache: true,
          },
          function (response) {
            if (response.success) {
              const exercises = {}
              response.data.forEach(function (item) {
                exercises[item.id] = item
              })
              self.itemsDict.exercises = exercises
              self.refreshUpdateForm()
              self.setValues()
              self.loaded()
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      }
    },
    setValues: function () {
      const self = this
      const notes = this.getNotes()
      const values = []
      let needsFixNotes = false

      if (this.config && this.config[this.mode] && this.config[this.mode].values) {
        this.config[this.mode].values.forEach(function (item) {
          if (!item.condition || (item.condition && self.client[item.condition.field] === item.condition.value)) {
            let items = null
            let value = null

            switch (item.category) {
              case 'foods': {
                items = []
                let foodsAdded = []
                let foodsList = null
                const foodsNamesDict = {}

                if (notes && notes[item.id] && notes[item.id].length) {
                  notes[item.id].forEach(function (id, index) {
                    const name = self.itemsDict.foods[id] ? self.itemsDict.foods[id].name.trim() : null
                    if (name && foodsAdded.indexOf(name) === -1) {
                      foodsAdded.push(name)
                      foodsNamesDict[name] = id
                    } else {
                      try {
                        if (typeof id === 'string') {
                          needsFixNotes = true
                          foodsList = foodsList || Object.values(self.itemsDict.foods)
                          const found = foodsList.find((fl) => fl.app_name.trim() === id.trim() || fl.name.trim() === id.trim())
                          if (found) {
                            notes[item.id][index] = found.id
                          }
                        }
                      } catch {}
                    }
                  })
                }

                if (foodsAdded.length) {
                  foodsAdded = foodsAdded.sort()
                  foodsAdded.forEach(function (it) {
                    items.push({
                      id: foodsNamesDict[it],
                      name: it,
                    })
                  })
                }
                break
              }

              case 'recipes': {
                items = []
                if (notes && notes[item.id] && notes[item.id].length) {
                  const recipesAdded = []
                  let recipesList = null
                  notes[item.id].forEach(function (rec, index) {
                    const recId = rec.id ? rec.id : rec
                    if (self.itemsDict.recipes[recId] && recipesAdded.indexOf(recId) === -1) {
                      recipesAdded.push(recId)
                      const currentName = self.itemsDict.recipes[recId] ? self.itemsDict.recipes[recId].name.trim() : ' - '
                      const nameSaved = rec.name ? rec.name.trim() : ''
                      items.push({
                        id: recId,
                        name:
                          rec.name && currentName !== nameSaved
                            ? nameSaved + ' (' + recId + ' - ' + window.strings['name_changed'] + ')'
                            : currentName,
                        description: rec.description,
                      })
                    } else {
                      try {
                        if (typeof rec === 'string') {
                          needsFixNotes = true
                          recipesList = recipesList || Object.values(self.itemsDict.recipes)
                          const found = recipesList.find((rl) => rl.name.trim() === rec.trim())
                          if (found) {
                            notes[item.id][index] = found.id
                          }
                        }
                      } catch {}
                    }
                  })
                }
                break
              }

              case 'tags': {
                items = []
                if (notes && notes[item.id] && notes[item.id].length) {
                  const tagItemsAdded = []
                  let itemsOptions = null
                  if (item.valuesEnums && Utils[item.valuesEnums]()) {
                    itemsOptions = {}
                    const valuesEnumsList = Utils[item.valuesEnums]()
                    valuesEnumsList.forEach(function (ve) {
                      itemsOptions[ve.value] = ve.label
                    })
                  }
                  notes[item.id].forEach(function (value) {
                    if (tagItemsAdded.indexOf(value) === -1) {
                      tagItemsAdded.push(value)
                      items.push({
                        name: itemsOptions && itemsOptions[value] ? itemsOptions[value] : value,
                      })
                    }
                  })
                }
                break
              }

              case 'user': {
                value = self.client[item.id]
                break
              }

              case 'exercises': {
                items = []
                const exercisesAdded = []
                if (notes && notes[item.id] && notes[item.id].length) {
                  notes[item.id].forEach(function (id) {
                    if (self.itemsDict.exercises[id] && exercisesAdded.indexOf(id) === -1) {
                      exercisesAdded.push(id)
                      items.push({
                        id: id,
                        name: self.itemsDict.exercises[id].name,
                      })
                    }
                  })
                }
                break
              }

              default: {
                value = notes && notes[item.id] ? (item.multiple ? notes[item.id].join(', ') : notes[item.id]) : '-'
                break
              }
            }

            if (!item.validate_items_count || (item.validate_items_count && items.length)) {
              let valueTitle = window.strings[item.title] ? window.strings[item.title] : item.title
              if (valueTitle.indexOf('?') > -1) {
                valueTitle = valueTitle.replace('?', '')
              }
              if (valueTitle.charAt(valueTitle.length - 1) === '.') {
                valueTitle = valueTitle.substring(0, valueTitle.length - 1)
              }
              values.push({
                id: item.id,
                title: valueTitle,
                type: item.type ? item.type : 'blue',
                icon: item.icon,
                showHasValue: item.showHasValue,
                items: items,
                value: value && window.strings[value] ? window.strings[value] : value,
              })
            }
          }
        })
      }

      this.values = values

      if (this.showMoreItemsButton) {
        const showAllItemsState = {}
        values.forEach(function (v) {
          if (v.items && v.items.length > self.showMoreItemsCount) {
            showAllItemsState[v.id] = false
          }
        })
        this.showAllItemsState = showAllItemsState
      }

      if (needsFixNotes && !this.notesAlreadyFixed) {
        this.notesAlreadyFixed = true
        this.updateNotes(
          notes,
          () => {
            this.setValues()
          },
          true,
        )
      }
    },
    setFieldsToEdit: function () {
      const self = this
      const fields = []
      if (this.config && this.config[this.mode] && this.config[this.mode].fields_to_edit) {
        this.config[this.mode].fields_to_edit.forEach(function (item) {
          if (!item.condition || (item.condition && self.client[item.condition.field] === item.condition.value)) {
            if (item.type === 'select' || item.type === 'multiselect') {
              if (item.itemsEnums && Utils[item.itemsEnums]) {
                item.items = Utils[item.itemsEnums]().filter(function (e) {
                  return !e.hide_notes
                })
                if (item.excludeEmptyItem) {
                  item.items = item.items.filter(function (t) {
                    return t.value
                  })
                }
              }
            }
            item.title = window.strings[item.title] ? window.strings[item.title] : item.title
            fields.push(item)
          }
        })
      }
      this.form[this.mode] = fields
    },
    refreshUpdateForm: function () {
      const notes = this.getNotes()
      const items = {}
      for (const key in this.itemsDict) {
        items[key] = []
        for (const id in this.itemsDict[key]) {
          items[key].push(this.itemsDict[key][id])
        }
      }
      for (let i = 0; i < this.form[this.mode].length; i++) {
        if (this.form[this.mode][i].type === 'select' && !this.form[this.mode][i].items.length) {
          this.form[this.mode][i].items = JSON.parse(JSON.stringify(items[this.form[this.mode][i].itemskey]))
        }
        if (notes && notes[this.form[this.mode][i].id]) {
          this.form[this.mode][i].value = notes[this.form[this.mode][i].id]
        }
      }
    },
    loaded: function () {
      this.isLoading = false
    },
    openUpdateDialog: function () {
      const notes = this.getNotes()
      if (this.form[this.mode]) {
        for (let i = 0; i < this.form[this.mode].length; i++) {
          this.form[this.mode][i].value = notes && notes[this.form[this.mode][i].id] ? notes[this.form[this.mode][i].id] : ''
          if (this.form[this.mode][i].is_string && window.strings[this.form[this.mode][i].value]) {
            this.form[this.mode][i].value = window.strings[this.form[this.mode][i].value]
          }
        }
      }
      this.dialogUpdate = true
    },
    refreshSelectItems: function (item) {
      const disableFields = []
      const enableFields = []
      let disableValues = []
      let enableValues = []
      if (item && item.id === 'accepted_foods') {
        disableFields.push('unaccepted_foods', 'intolerant_foods')
        enableFields.push('accepted_foods')
      } else if (item && (item.id === 'unaccepted_foods' || item.id === 'intolerant_foods')) {
        disableFields.push('accepted_foods')
        enableFields.push('unaccepted_foods', 'intolerant_foods')
      }
      disableFields.forEach((fieldId) => {
        const fieldItem = this.form[this.mode].find((i) => i.id === fieldId)
        if (fieldItem) {
          disableValues = disableValues.concat(fieldItem.value)
        }
      })
      disableValues = [...new Set(disableValues)]
      enableFields.forEach((fieldId) => {
        const fieldItem = this.form[this.mode].find((i) => i.id === fieldId)
        if (fieldItem) {
          enableValues = enableValues.concat(fieldItem.value)
        }
      })
      if (enableValues.length) {
        enableValues = [...new Set(enableValues)]
        disableValues = disableValues.filter(function (v) {
          return enableValues.indexOf(v) === -1
        })
      }
      item.items.forEach((subItem) => {
        subItem.disabled = disableValues.includes(subItem.id)
      })
    },
    changeSelect: function (item) {
      try {
        const targetFieldId = item.id === 'exclude_recipes' ? 'include_recipes' : 'exclude_recipes'
        const targetField = this.form[this.mode].find((i) => i.id === targetFieldId)
        if (targetField && targetField.value) {
          const itemValueIds = item.value.map((val) => (val.id ? val.id : val))
          targetField.value.some((field, index) => {
            const id = field.id ? field.id : field
            if (itemValueIds.includes(id)) {
              targetField.value.splice(index, 1)
              return true
            }
            return false
          })
        }
      } catch {}
    },
    closeUpdateDialog: function () {
      this.dialogUpdate = false
    },
    saveUpdateDialog: function () {
      const notes = this.getNotes()
      for (let i = 0; i < this.form[this.mode].length; i++) {
        let val = this.form[this.mode][i].value
        if (this.form[this.mode][i].returnObject && this.form[this.mode][i].value) {
          if (this.form[this.mode][i].multiple) {
            val = val.map((a) => ({ id: a.id, name: a.name }))
          } else {
            val = {
              id: this.form[this.mode][i].value.id,
              name: this.form[this.mode][i].value.name,
            }
          }
        }
        notes[this.form[this.mode][i].id] = val
      }
      this.updateExcludeExercises(notes)
      this.updateNotes(notes)
      this.closeUpdateDialog()
    },
    updateExcludeExercises: function (notes) {
      try {
        if (this.mode !== 'training') return

        const workoutsFlags = {}
        const items = Utils.getWorkoutFlags()

        if (!items || !items.length) return

        Object.values(this.itemsDict.exercises).forEach((exercise) => {
          if (exercise.flags) {
            items.forEach((item) => {
              if (exercise.flags & item.value) {
                if (!workoutsFlags[item.id]) {
                  workoutsFlags[item.id] = {
                    answer: item.answer,
                    field: item.field,
                    condition: item.condition,
                    data: [],
                  }
                }
                workoutsFlags[item.id].data.push(exercise.id)
              }
            })
          }
        })

        Object.entries(notes).forEach(([id, noteValue]) => {
          const flagData = workoutsFlags[id]

          if (noteValue && flagData) {
            const { answer } = flagData
            let answerValueValid = answer.some((ans) => {
              const match = ans.match(/(\d+)/)
              const answerVal = match ? parseInt(match[0], 10) : null

              if (ans.includes('>') && noteValue > answerVal) return true
              if (ans.includes('<') && noteValue < answerVal) return true
              return answer.includes(noteValue)
            })

            if (answerValueValid) {
              notes.exclude_exercises = notes.exclude_exercises || []
              flagData.data.forEach((exerciseId) => {
                if (!notes.exclude_exercises.includes(exerciseId)) {
                  notes.exclude_exercises.push(exerciseId)
                }
              })
            }
          }
        })
      } catch {}
    },
    updateNotes: function (data, callback, disableLoading) {
      const self = this
      if (!disableLoading) {
        this.$isLoading(true)
      }
      Api.updateUser(
        {
          id: this.client.id,
          notes: JSON.stringify(data),
        },
        function (response) {
          if (!disableLoading) {
            self.$isLoading(false)
          }
          if (response.success) {
            self.client.setValue('notes', response.data.notes)
            Utils.setStorage('client', self.client)
            self.setValues()
            if (callback) {
              callback()
            }
            if (!disableLoading) {
              self.$alert(window.strings['notes_saved'], '', 'success', Utils.getAlertOptions())
            }
            window.postMessage(
              JSON.stringify({
                event: 'client-notes-saved',
              }),
              '*',
            )
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    getNotes: function () {
      let data = {}
      try {
        if (this.client.notes) {
          data = JSON.parse(this.client.notes)
        }
      } catch {}
      return data
    },
    showInformation: function (info) {
      this.$alert(info, '', 'info', Utils.getAlertOptions())
    },
  },
}
</script>
